import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Container from "./Container";

import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Box paddingTop={"3rem"} paddingBottom={"1rem"} bgcolor={"#0D1C3D"}>
        <Container>
          <Box paddingBottom={"1.5rem"}>
            <Logo style={{ width: "200px", height: "auto" }} />
          </Box>
          <Grid container rowGap={"15px"}>
            <Grid item xs={12} sm={6}>
              <Grid container rowGap={"15px"}>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    PrämienVergleich unterstützt Sie als unabhängige
                    Vergleichsplattform dabei, sich in der komplexen Welt der
                    Versicherungen und Finanzdienstleister zurechtzufinden.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    In nur wenigen Klicks können Sie verschiedene Angebote
                    vergleichen und unverbindliche Angebote anfordern.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    PrämienVergleich bietet Ihnen kostenlose Unterstützung und
                    Begleitung bis zum Abschluss.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Box
              position={"relative"}
              item
              xs={12}
              sm={6}
              display={"flex"}
              flexDirection={"column"}
              sx={{ marginLeft: "20px" }}
            >
              <h1 style={{ color: "white" , marginTop: "0"}}>Über uns</h1>
              <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                <Link style={{ color: "white", textDecoration: "none" }}>
                  Datenschutz
                </Link>
                <Link style={{ color: "white", textDecoration: "none" }}>
                  Impressum
                </Link>
              </Box>
            </Box> */}
            <Grid item xs={12} sm={6} md={4} lg={3}  style={{marginLeft: "auto"}}>
              <Grid container rowGap={"15px"}>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    <Link style={{ color: "white", textDecoration: "none" }}>
                      <h1 style={{ color: "white", marginTop: "0" }}>
                        Über uns
                      </h1>
                    </Link>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    <a style={{ color: "white", textDecoration: "none" }} href={'/datenschutz'}>
                      Datenschutz
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={"#fff"} fontSize={"14PX"}>
                    <a style={{ color: "white", textDecoration: "none" }} href={'/impressum'}>
                      Impressum
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography
            color={"#fff"}
            fontSize={"14px"}
            textAlign={"center"}
            paddingTop={"3rem"}
          >
            © 2024 PrämienVergleich
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
