import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';

import Form from './pages/Form';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/form" element={<Form />} />
          <Route exact path="/impressum" element={<Impressum />} />
          <Route exact path="/datenschutz" element={<Datenschutz />} />
        </Routes>
      </BrowserRouter>
      
    </>
  );
}

export default App;
