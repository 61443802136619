import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import Container from '../../components/Container'
import { ReactComponent as Stars } from '../../assets/svgs/stars.svg'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import SectionSpacing from '../../components/SectionSpacing';

const Testimonials = () => {
    return (
        <Box paddingBlock={'3rem'} bgcolor={'#F7F7F7'}>
           
            <Container>
                <Typography fontSize={{ xs: '22px', sm: '25px', md: '35px', lg: '40px' }} fontWeight={500} textAlign={'center'} variant='h1' marginBottom={{ xs: '3rem', md: '1.5rem' }}>
                    Was unsere Kunden sagen
                </Typography>

                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    initialSlide={1}
                    loop={true}
                    centeredSlides={true}
                    pagination={{ clickable: true }}  // Add pagination
                    modules={[Pagination]}
                    style={{ paddingBottom: '40px' }}
                    className='mySwiper'
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20,

                        },
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            centeredSlides: false,
                        },
                        1000: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                            centeredSlides: true,
                        },
                    }}
                >
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Mehdi
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                       Ich wollte einen digitalen Vergleich und habe ihn schnell erhalten. Dank der klaren Anleitung konnte ich viel sparen.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Benjamin
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                        Nach meinem Umzug von Deutschland in die Schweiz war ich auf der Suche nach Informationen über das Gesundheitssystem. Prämienvergleich bot klare Informationen und persönliche Beratung, die mir bei meiner Entscheidung geholfen haben.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Thomas
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                        Ich war seit über 10 Jahren bei derselben Krankenversicherung und war unsicher, ob ich wechseln sollte. Der Vergleich hat mir CHF 250 pro Monat gespart, und der Kundenservice war ausgezeichnet.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Mehdi
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                       Ich wollte einen digitalen Vergleich und habe ihn schnell erhalten. Dank der klaren Anleitung konnte ich viel sparen.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Benjamin
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                        Nach meinem Umzug von Deutschland in die Schweiz war ich auf der Suche nach Informationen über das Gesundheitssystem. Prämienvergleich bot klare Informationen und persönliche Beratung, die mir bei meiner Entscheidung geholfen haben.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    <SwiperSlide style={{ width: 'min-content' }}>
                        <Box padding={'40px 25px'} bgcolor={'#fff'} borderRadius={'12px'} >
                            <Grid container rowSpacing={'1rem'} justifyContent={'center'}>
                                <Grid item xs>
                                    <Grid container alignItems={'center'} columnSpacing={'10px'}>

                                        <Grid item xs>
                                            <Typography fontSize={'16px'} fontWeight={700}>
                                                Thomas
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Stars />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Typography textAlign={'center'} fontSize={'12px'} >
                                        Ich war seit über 10 Jahren bei derselben Krankenversicherung und war unsicher, ob ich wechseln sollte. Der Vergleich hat mir CHF 250 pro Monat gespart, und der Kundenservice war ausgezeichnet.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                    </SwiperSlide>
                    
                </Swiper>
            </Container>
        </Box>
    )
}

export default Testimonials