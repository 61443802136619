import { Box } from '@mui/material'
import React from 'react'

const SectionSpacing = () => {
    return (
        <Box height={{ xs: '80px', lg: '150px' }}>

        </Box>
    )
}

export default SectionSpacing