import { Box } from '@mui/material'
import React from 'react'

const Container = ({ children }) => {
    return (
        <Box maxWidth={1300} paddingInline={'1.5rem'} margin={'auto'}>
            {children}
        </Box>
    )
}

export default Container