import React from "react";
import SectionSpacing from "../../components/SectionSpacing";
import { Box, Grid, Typography } from "@mui/material";

import { ReactComponent as HeroImage } from "../../assets/svgs/heroIllustration.svg";
import Container from "../../components/Container";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <Box bgcolor={"#0D1C3D"} pt={"70px"}>
      <Container>
        <SectionSpacing />
        <Grid container alignItems={"center"} spacing={"50px"}>
          <Grid item xs={12} md={5} xl={6}>
            <Typography
              variant="h1"
              fontWeight={400}
              fontSize={{
                xs: "25px",
                sm: "30px",
                md: "40px",
                lg: "50px",
                xl: "66px"
              }}
              color={"#fff"}
              marginBottom={"2rem"}
            >
              Krankenkasse in der Schweiz
            </Typography>
            <Typography
              fontWeight={400}
              fontSize={{ xs: "16px", xl: "18px" }}
              color={"#fff"}
              marginBottom={"2rem"}
            >
              Der starke Wettbewerb unter den Krankenkassen in der Schweiz ist
              vorteilhaft, da er dazu beiträgt, die Qualität der angebotenen
              Leistungen auf einem hohen Niveau zu halten und gleichzeitig die
              Kosten für die Versicherten zu minimieren. Es ist daher
              entscheidend, dass Menschen in der Schweiz ihre Prämien sorgfältig
              vergleichen, um sicherzustellen, dass sie die passende
              Krankenkasse wählen und nicht mehr für ihre Versicherung bezahlen,
              als nötig.{" "}
            </Typography>

            <Link
              to={"/form"}
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer"
              }}
            >
              <Button secondary={true}>Jetzt vergleichen</Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={"auto"}>
            <HeroImage width={"100%"} />
          </Grid>
        </Grid>
        <SectionSpacing />
      </Container>
    </Box>
  );
};

export default HeroSection;
