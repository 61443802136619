import { Box, Container } from "@mui/material";
import React from "react";
import Footer from "./Footer";

const Impressum = () => {
  return (
   <Box fontFamily={"Roboto, Helvetica, Arial, sans-serif"}>
      <Box maxWidth={1300} paddingInline={'1.5rem'} paddingTop={15} paddingBottom={15} margin={'auto'}>
      <div className="text-start container-xl px-4 px-xl-0 bodyCounterPadding">
        <p>
          <strong>
            <i>Kontakt-Adresse</i>
          </strong>
        </p>
        <p>
          Kutiza LLC
          <br />
          Bajram Kelmendi 1
          <br />
          10000 Prishtine.
          <br />
        </p>
        <p>
          E-Mail:
          <br />
          info@kutiza.com
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Vertretungsberechtigte Person(en)</i>
          </strong>
        </p>
        Enis Demolli, Gesch&auml;ftsinhaber Kutiza LLC
        <br />
        <br />
        <p>
          <strong>
            <i>Handelsregister-Eintrag</i>
          </strong>
        </p>
        Eingetragener Firmenname:
        <br />
        <strong>Kutiza LLC</strong>
        <br />
        <br />
        Unternehmens-Nr (UID):
        <br />
        <strong>811355347</strong>
        <br />
        <br />
        <p>
          {/* Mehrwertsteuer-Nummer */}
          {/* <br /> */}
          {/* <strong>CHE-215.063.772 MWST</strong> */}
          {/* <br /> */}
          {/* <br /> */}
          {/* <br /> */}
          <p>
            <strong>
              <i>Haftungsausschluss</i>
            </strong>
          </p>
          Der Autor &uuml;bernimmt keinerlei Gew&auml;hr hinsichtlich der
          inhaltlichen Richtigkeit, Genauigkeit, Aktualit&auml;t,
          Zuverl&auml;ssigkeit und Vollst&auml;ndigkeit der Informationen.
        </p>
        <p>
          Haftungsanspr&uuml;che gegen den Autor wegen Sch&auml;den materieller
          oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
          Nichtnutzung der ver&ouml;ffentlichten Informationen, durch Missbrauch
          der Verbindung oder durch technische St&ouml;rungen entstanden sind,
          werden ausgeschlossen.
        </p>
        <p>
          Alle Angebote sind unverbindlich. Der Autor beh&auml;lt es sich
          ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
          besondere Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu
          l&ouml;schen oder die Ver&ouml;ffentlichung zeitweise oder
          endg&uuml;ltig einzustellen.
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Haftungsausschluss f&uuml;r Links</i>
          </strong>
        </p>
        <p>
          Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
          Verantwortungsbereichs. Es wird jegliche Verantwortung f&uuml;r solche
          Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
          erfolgen auf eigene Gefahr des jeweiligen Nutzers.
        </p>
        <br />
        <br />
        <p>
          <strong>
            <i>Urheberrechte</i>
          </strong>
        </p>
        <p>
          Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
          anderen Dateien auf dieser Website, geh&ouml;ren ausschliesslich{" "}
          <strong>der Firma Kutiza LLC</strong> oder den speziell genannten
          Rechteinhabern. F&uuml;r die Reproduktion jeglicher Elemente ist die
          schriftliche Zustimmung des Urheberrechtstr&auml;gers im Voraus
          einzuholen.
        </p>
        <br />
        Quelle:
        <a href="https://www.swissanwalt.ch" target="_blank" rel="noreferrer">
          SwissAnwalt
        </a>
      </div>
    </Box>
    <Footer/>
   </Box>
  );
};

export default Impressum;
