import React from "react";
import Container from "../../components/Container";
import SectionSpacing from "../../components/SectionSpacing";
import { Box, Grid, Typography } from "@mui/material";

import { ReactComponent as Laptop } from "../../assets/svgs/whyLaptop.svg";
import { ReactComponent as Database } from "../../assets/svgs/whyDatabase.svg";
import { ReactComponent as Network } from "../../assets/svgs/whyNetwork.svg";

const WhySection = () => {
  return (
    <Container>
      <SectionSpacing />
      <Typography
        fontSize={{ xs: "22px", sm: "25px", md: "35px", lg: "40px" }}
        fontWeight={500}
        textAlign={"center"}
        variant="h1"
        marginBottom={"3rem"}
      >
        Warum PrämienVergleich
      </Typography>
      <Grid container spacing={"30px"} justifyContent={"center"}>
        <Grid item xs={12} sm={6} lg={4}>
          <Box
            boxSizing={"border-box"}
            height={"100%"}
            width={"100%"}
            maxHeight={"435px"}
            padding={{ xs: "25px", md: "30px" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div style={{ textAlign: "center" }}>
              <Laptop />
            </div>
            <Typography
              color={"#0078B3"}
              fontSize={{ xs: "20px", sm: "22px" }}
              fontWeight={400}
              marginBlock={"10px"}
            >
              Beratung anfordern
            </Typography>
            <Typography
              fontSize={{ xs: "16px" }}
              fontWeight={400}
              width={"100%"}
              textAlign={"center"}
            >
              Fordern Sie über das Kontaktformular, den Chatbot oder den
              Livechat eine kostenlose und unverbindliche Beratung bei Ihnen
              Zuhause oder per Zoom an.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box
            boxSizing={"border-box"}
            height={"100%"}
            width={"100%"}
            maxHeight={"435px"}
            padding={{ xs: "25px", md: "30px" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div style={{ textAlign: "center" }}>
              <Database />
            </div>
            <Typography
              color={"#0078B3"}
              fontSize={{ xs: "20px", sm: "22px" }}
              fontWeight={400}
              marginBlock={"10px"}
            >
              Beratungstermin
            </Typography>
            <Typography
              fontSize={{ xs: "16px" }}
              fontWeight={400}
              width={"100%"}
              textAlign={"center"}
            >
              Ihr Kundenberater erstellt basierend auf Ihren Angaben
              verschiedene Angebote und stellt Ihnen bei Ihrem Termin die
              jeweiligen Vorteile vor, während er Ihre Fragen beantwortet.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Box
            boxSizing={"border-box"}
            height={"100%"}
            width={"100%"}
            maxHeight={"435px"}
            padding={{ xs: "25px", md: "30px" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div style={{ textAlign: "center" }}>
              <Network />
            </div>
            <Typography
              color={"#0078B3"}
              fontSize={{ xs: "20px", sm: "22px" }}
              fontWeight={400}
              marginBlock={"10px"}
            >
              Abschluss
            </Typography>
            <Typography
              fontSize={{ xs: "16px" }}
              fontWeight={400}
              width={"100%"}
              textAlign={"center"}
            >
              Zusammen mit Ihrem Kundenberater füllen Sie die erforderlichen
              Unterlagen aus. Der Kundenberater kümmert sich anschließend um den
              Rest.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <SectionSpacing />
    </Container>
  );
};

export default WhySection;
