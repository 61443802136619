import axios from "axios";
import React, { useState } from "react";
import Container from "../components/Container";
import { Box, Grid, Typography } from "@mui/material";
import SectionSpacing from "../components/SectionSpacing";

export const krankenkasses = [
  "CSS",
  "Helsana",
  "Swica",
  "Assura",
  "Sanitas",
  "Visana",
  "Galenos",
  "Sympany",
  "KPT",
  "Aquilana",
  "Intras",
  "ÖKK",
  "Progrès",
  "Swisscare",
  "Compact",
  "EGK",
  "Groupe Mutuel",
  "Mutuel Assurance",
  "Philos",
  "Sana24",
  "SLKK",
  "Supra",
  "Visperterminen",
  "Vivao Sympany",
  "Arcosana",
  "Atupri",
  "Avanex",
  "CPT",
  "Easy Sana",
  "Glarner",
  "Innova",
  "Kolping",
  "Lumneziana",
  "Mobi24"
];

const Form = () => {
  const [show, setShow] = useState(false);
  const [Loading, setLoading] = useState(false);

  const Submit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        "https://sendemail.insulana.ch/send-email-pramienvergleich",
        FormData
      )
      .then(() => {
        axios
          .post("https://insusales.com/pramien-leads", FormData)
          .then((res) => {
            console.log(res);
            setShow(true);
          })
          .catch((err) => {
            console.log(err);
            setShow(false);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setShow(
          "Beim Absenden ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal"
        );
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setShow(false);
          setFormData({});
        }, [5000]);
      });
  };

  const [FormData, setFormData] = useState({
    vorname: "",
    nachname: "",
    geburtsdatum: "",
    tel: "",
    plz: "",
    ort: "",
    strasse: "",
    krankenkasse: "",
    anzahl_personen:""
  });

  return (
    <Container>
      <SectionSpacing />
      <Typography
        paddingTop={{ xs: "20px", md: "0px" }}
        fontSize={{ xs: "22px", sm: "25px", md: "35px", lg: "40px" }}
        fontWeight={500}
        textAlign={"center"}
        variant="h1"
        marginBottom={"3rem"}
      >
        Kontaktieren Sie uns
      </Typography>
      <Box
        boxShadow={"0px 3.86px 19.31px 0px #0000000A"}
        border={"1px solid #0000001A"}
        borderRadius={"4px"}
        maxWidth={"800px"}
        margin={"auto"}
        padding={{ xs: "25px", md: "40px" }}
      >
        {show ? (
          <div
            className="sucess-message"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              minHeight: "300px"
            }}
          >
            <img
              src="https://icons.veryicon.com/png/o/miscellaneous/8atour/submit-successfully.png"
              style={{ width: "100px" }}
            />
            <h1>Vielen Dank für die Übermittlung</h1>
          </div>
        ) : (
          <>
            {" "}
            <Typography
              fontSize={{ xs: "18px", md: "22px" }}
              variant="h1"
              marginBottom={"1rem"}
            >
              Kontaktieren Sie uns
            </Typography>
            <form style={{ marginBottom: "0px" }} onSubmit={Submit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.20648 15.4816C5.79171 16.324 2.08227 18.0441 4.34157 20.1966C5.44522 21.248 6.6744 22 8.21977 22H17.038C18.5834 22 19.8126 21.248 20.9162 20.1966C23.1755 18.0441 19.4661 16.324 18.0513 15.4816C14.7337 13.5061 10.5241 13.5061 7.20648 15.4816Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1289 6.5C17.1289 8.98528 15.1142 11 12.6289 11C10.1436 11 8.12891 8.98528 8.12891 6.5C8.12891 4.01472 10.1436 2 12.6289 2C15.1142 2 17.1289 4.01472 17.1289 6.5Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      type="text"
                      className="newInputStyle"
                      placeholder="Vorname"
                      value={FormData.vorname}
                      onChange={(e) =>
                        setFormData({ ...FormData, vorname: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.20648 15.4816C5.79171 16.324 2.08227 18.0441 4.34157 20.1966C5.44522 21.248 6.6744 22 8.21977 22H17.038C18.5834 22 19.8126 21.248 20.9162 20.1966C23.1755 18.0441 19.4661 16.324 18.0513 15.4816C14.7337 13.5061 10.5241 13.5061 7.20648 15.4816Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1289 6.5C17.1289 8.98528 15.1142 11 12.6289 11C10.1436 11 8.12891 8.98528 8.12891 6.5C8.12891 4.01472 10.1436 2 12.6289 2C15.1142 2 17.1289 4.01472 17.1289 6.5Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      type="text"
                      className="newInputStyle"
                      placeholder="Nachname"
                      value={FormData.nachname}
                      onChange={(e) =>
                        setFormData({ ...FormData, nachname: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "calc(50% - 12px)"
                        }}
                      >
                    <svg width="25px" height="24px" viewBox="0 -2.19 47.336 47.336" xmlns="http://www.w3.org/2000/svg">
                      <g id="Group_40" data-name="Group 40" transform="translate(-66.66 -364.396)">
                        <path id="Rectangle_15" data-name="Rectangle 15" d="M4.351,0H40.984a4.351,4.351,0,0,1,4.351,4.351V22.117a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V4.351A4.351,4.351,0,0,1,4.351,0Z" transform="translate(67.66 383.243)" fill="none" stroke="#D3D3D3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_88" data-name="Path 88" d="M113,389.249a3.778,3.778,0,0,1-3.778,3.778h0a3.779,3.779,0,0,1-3.778-3.778,3.778,3.778,0,0,1-3.778,3.778h0a3.779,3.779,0,0,1-3.778-3.778,3.778,3.778,0,0,1-3.778,3.778h0a3.779,3.779,0,0,1-3.778-3.778,3.778,3.778,0,0,1-3.778,3.778h0a3.779,3.779,0,0,1-3.778-3.778,3.778,3.778,0,0,1-3.778,3.778h0a3.778,3.778,0,0,1-3.777-3.778,3.779,3.779,0,0,1-3.778,3.778h0a3.778,3.778,0,0,1-3.778-3.778" fill="none" stroke="#D3D3D3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <g id="Group_39" data-name="Group 39">
                          <rect id="Rectangle_16" data-name="Rectangle 16" width="4.333" height="9.73" transform="translate(87.931 373.513)" fill="none" stroke="#D3D3D3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                          <path id="Path_89" data-name="Path 89" d="M92.825,370.333a2.727,2.727,0,1,1-5.455,0c0-1.506,2.727-4.937,2.727-4.937S92.825,368.827,92.825,370.333Z" fill="none" stroke="#D3D3D3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </g>
                      </g>
                    </svg>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          lineHeight: 0,
                          left: "40px",
                          top: "25px",
                          color: "#000",
                          fontSize: "14px"
                        }}
                      >
                      </div>
                      <input
                        className="newInputStyle"
                        placeholder=""
                        name="geburtsdatum"
                        type="date"
                        required
                        style={{ paddingLeft: "75px" }}
                        value={FormData.geburtsdatum}
                        onChange={(e) =>
                          setFormData({ ...FormData, geburtsdatum: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          top: "calc(50% - 12px)"
                        }}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.40653 11.9424C3.45851 10.2893 3.00075 8.93948 2.72474 7.57121C2.31653 5.54758 3.25072 3.57081 4.79829 2.30947C5.45235 1.77638 6.20214 1.95852 6.58891 2.6524L7.46209 4.21891C8.1542 5.46057 8.50025 6.08139 8.43161 6.73959C8.36297 7.39779 7.89627 7.93386 6.96288 9.00601L4.40653 11.9424ZM4.40653 11.9424C6.32541 15.2883 9.33674 18.3013 12.6865 20.2224M12.6865 20.2224C14.3396 21.1704 15.6894 21.6282 17.0577 21.9042C19.0813 22.3124 21.0581 21.3782 22.3194 19.8306C22.8525 19.1766 22.6704 18.4268 21.9765 18.04L20.41 17.1668C19.1683 16.4747 18.5475 16.1287 17.8893 16.1973C17.2311 16.2659 16.695 16.7326 15.6229 17.666L12.6865 20.2224Z"
                            stroke="#D3D3D3"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          lineHeight: 0,
                          left: "40px",
                          top: "25px",
                          color: "#000",
                          fontSize: "14px"
                        }}
                      >
                        <span>+41</span>
                      </div>
                      <input
                        className="newInputStyle"
                        placeholder=""
                        name="tel"
                        type="tel"
                        required
                        style={{ paddingLeft: "75px" }}
                        pattern="^(?:[\.\-\s]?(21|22|24|26|27|31|32|33|34|41|43|44|51|52|55|56|58|61|62|71|74|76|77|78|79|81|91))(?:[\.\-\s]?\d\d\d)(?:[\.\-\s]?\d\d){2}$"
                        value={FormData.tel}
                        onChange={(e) =>
                          setFormData({ ...FormData, tel: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.12891 9H9.12891"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.1289 8V4C18.1289 2.89543 19.0243 2 20.1289 2"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.1289 18L13.1289 22"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M18.1289 5.15889C17.164 5 15.8881 5 14.0039 5H11.2539C8.33562 5 6.87647 5 5.77192 5.59039C4.89978 6.05656 4.18547 6.77088 3.7193 7.64302C3.12891 8.74757 3.12891 10.2067 3.12891 13.125C3.12891 14.876 3.12891 15.7515 3.48314 16.4142C3.76284 16.9375 4.19143 17.3661 4.71472 17.6458C5.37745 18 6.25293 18 8.00391 18H17.2539C19.0049 18 19.8804 18 20.5431 17.6458C21.0664 17.3661 21.495 16.9375 21.7747 16.4142C22.1289 15.7515 22.1289 14.876 22.1289 13.125C22.1289 10.2067 22.1289 8.74757 21.5385 7.64302C21.3645 7.31755 21.156 7.01406 20.9176 6.73725"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M13.1295 18.001V11.001C13.1295 10.072 13.1295 9.60746 13.0679 9.2188C12.7291 7.07934 11.0511 5.40139 8.91167 5.06253C8.8298 5.04956 8.74457 5.03933 8.65234 5.03125"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      type="text"
                      className="newInputStyle"
                      placeholder="PLZ"
                      value={FormData.plz}
                      onChange={(e) =>
                        setFormData({ ...FormData, plz: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.1289 11C16.1289 12.933 14.5619 14.5 12.6289 14.5C10.6959 14.5 9.12891 12.933 9.12891 11C9.12891 9.067 10.6959 7.5 12.6289 7.5C14.5619 7.5 16.1289 9.067 16.1289 11Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                        />
                        <path
                          d="M12.6289 2C17.4995 2 21.6289 6.03298 21.6289 10.9258C21.6289 15.8965 17.4322 19.3847 13.5559 21.7567C13.2734 21.9162 12.954 22 12.6289 22C12.3039 22 11.9844 21.9162 11.7019 21.7567C7.8328 19.3616 3.62891 15.9137 3.62891 10.9258C3.62891 6.03298 7.75835 2 12.6289 2Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      type="text"
                      className="newInputStyle"
                      placeholder="Ort"
                      value={FormData.ort}
                      onChange={(e) =>
                        setFormData({ ...FormData, ort: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.54762 4.43926C6.09006 3.73152 6.36128 3.37764 6.73206 3.18882C7.10284 3 7.5265 3 8.37382 3H10.7895C11.6566 3 12.0901 3 12.3595 3.29289C12.6289 3.58579 12.6289 4.05719 12.6289 5V9H8.37382C7.5265 9 7.10284 9 6.73206 8.81118C6.36128 8.62236 6.09006 8.26848 5.54762 7.56074L5.33274 7.28037C4.86352 6.66816 4.62891 6.36205 4.62891 6C4.62891 5.63795 4.86352 5.33184 5.33274 4.71963L5.54762 4.43926Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.7102 9.43926C19.1677 8.73152 18.8965 8.37764 18.5258 8.18882C18.155 8 17.7313 8 16.884 8H12.6289V14H16.884C17.7313 14 18.155 14 18.5258 13.8112C18.8965 13.6224 19.1678 13.2685 19.7102 12.5607L19.9251 12.2804C20.3943 11.6682 20.6289 11.362 20.6289 11C20.6289 10.638 20.3943 10.3318 19.9251 9.71963L19.7102 9.43926Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.6289 21L12.6289 4"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.62891 21H15.6289"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      type="text"
                      className="newInputStyle"
                      placeholder="Strasse"
                      value={FormData.strasse}
                      onChange={(e) =>
                        setFormData({ ...FormData, strasse: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.54762 4.43926C6.09006 3.73152 6.36128 3.37764 6.73206 3.18882C7.10284 3 7.5265 3 8.37382 3H10.7895C11.6566 3 12.0901 3 12.3595 3.29289C12.6289 3.58579 12.6289 4.05719 12.6289 5V9H8.37382C7.5265 9 7.10284 9 6.73206 8.81118C6.36128 8.62236 6.09006 8.26848 5.54762 7.56074L5.33274 7.28037C4.86352 6.66816 4.62891 6.36205 4.62891 6C4.62891 5.63795 4.86352 5.33184 5.33274 4.71963L5.54762 4.43926Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.7102 9.43926C19.1677 8.73152 18.8965 8.37764 18.5258 8.18882C18.155 8 17.7313 8 16.884 8H12.6289V14H16.884C17.7313 14 18.155 14 18.5258 13.8112C18.8965 13.6224 19.1678 13.2685 19.7102 12.5607L19.9251 12.2804C20.3943 11.6682 20.6289 11.362 20.6289 11C20.6289 10.638 20.3943 10.3318 19.9251 9.71963L19.7102 9.43926Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.6289 21L12.6289 4"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.62891 21H15.6289"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <input
                      required
                      id="anzahl_personen"
                      type="text"
                      className="newInputStyle"
                       placeholder="anzahl_personen"
                      value={FormData.anzahl_personen}
                      onChange={(e) =>
                        setFormData({ ...FormData, anzahl_personen: e.target.value })
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "calc(50% - 12px)"
                      }}
                    >
                      <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.6271 2C9.61933 2 7.66909 4.01899 5.36262 4.7549C4.42479 5.05413 3.95588 5.20374 3.76611 5.41465C3.57634 5.62556 3.52077 5.93375 3.40963 6.55013C2.22034 13.146 4.8198 19.244 11.0192 21.6175C11.6853 21.8725 12.0183 22 12.6304 22C13.2424 22 13.5755 21.8725 14.2415 21.6175C20.4405 19.2439 23.0375 13.146 21.8479 6.55013C21.7367 5.93364 21.6811 5.6254 21.4913 5.41449C21.3015 5.20358 20.8326 5.05405 19.8948 4.75499C17.5874 4.01915 15.635 2 12.6271 2Z"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.6289 7L12.6289 9"
                          stroke="#D3D3D3"
                          stroke-width="1.5"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    <select
                      name="krankenkasse"
                      id=""
                      className="newInputStyle"
                      style={{ paddingLeft: "40px" }}
                      required
                      value={FormData.krankenkasse}
                      onChange={(e) =>
                        setFormData({
                          ...FormData,
                          krankenkasse: e.target.value
                        })
                      }
                    >
                      <option value={""} disabled hidden>
                        Krankenkasse
                      </option>
                      {krankenkasses.map((krankekasse) => (
                        <option key={krankekasse} value={krankekasse}>
                          {krankekasse}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"} columnSpacing={"5px"}>
                    <Grid item xs={"auto"}>
                      <input type="checkbox" id="checkbox" required />
                    </Grid>
                    <Grid item xs>
                      <label style={{ fontSize: "14px" }} htmlFor="checkbox">
                        {" "}
                        Durch das Klicken auf 'Absenden' stimme ich den
                        Allgemeinen Geschäftsbedingungen zu.
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <button
                    type="submit"
                    disabled={Loading}
                    style={{
                      color: "#fff",
                      height: "50px",
                      width: "100%",
                      backgroundColor: "#0A1629",
                      border: "none",
                      borderRadius: "12px",
                      cursor: "pointer",
                      fontWeight: 700
                    }}
                  >
                    Absenden
                  </button>

                  {show.length > 0 && (
                    <Box pt={2} style={{ fontSize: "12px" }}>
                      <span>{show}</span>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Box>
      <SectionSpacing />
    </Container>
  );
};

export default Form;
