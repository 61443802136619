import React from 'react'

const Button = ({ children, secondary, ...rest }) => {
    return (
        <button  style={{
            padding: '10px 25px',
            color: secondary ? '#fff' : '#0D1C3D',
            border: secondary ? '1px solid #fff' : '1px solid #0D1C3D',
            borderRadius: '12px',
            backgroundColor: secondary ? 'transparent' : '#fff',
            height: '45px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            fontWeight: 700,
            cursor: "pointer"

        }} {...rest}>
            {children}
        </button >
    )
}

export default Button