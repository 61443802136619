import React, { useState } from 'react'
import Container from '../../components/Container'
import { Box, Grid, Typography } from '@mui/material'
import SectionSpacing from '../../components/SectionSpacing'

import { ReactComponent as Arrow } from '../../assets/svgs/faqArrow.svg'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
const Faq = () => {

    const [ActiveElement, setActiveElement] = useState(0)


    return (
        <>
            <Container>
                <SectionSpacing />
                <Typography fontSize={{ xs: '22px', sm: '25px', md: '35px', lg: '40px' }} fontWeight={500} textAlign={'center'} variant='h1' marginBottom={'3rem'}>
                    Häufig gestellte Fragen.
                </Typography>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} md={6}>
                        <Box border={'1px solid #00000005'} >
                            <div onClick={() => { setActiveElement(0) }} style={{ cursor: 'pointer' }}>

                                <Box borderBottom={'1px solid #00000005'} paddingBlock={'20px '} paddingLeft={'12px'} paddingRight={'20px'}
                                    bgcolor={ActiveElement === 0 ? '#FAFBFF' : '#fff'}>
                                    <Grid container alignItems={'center'} columnGap={'10px'}>
                                        <Grid item xs>
                                            <Typography fontSize={{ xs: '16px' }}>
                                                Welche Leistungen sind in meinem Plan abgedeckt?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Arrow />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                            <div onClick={() => { setActiveElement(1) }} style={{ cursor: 'pointer' }}>
                                <Box borderBottom={'1px solid #00000005'} paddingBlock={'20px '} paddingLeft={'12px'} paddingRight={'20px'}
                                    bgcolor={ActiveElement === 1 ? '#FAFBFF' : '#fff'}>
                                    <Grid container alignItems={'center'} columnGap={'10px'}>
                                        <Grid item xs>
                                            <Typography fontSize={{ xs: '16px' }}>
                                                Kann ich meinen Versicherungsschutz anpassen?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Arrow />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                            <div onClick={() => { setActiveElement(2) }} style={{ cursor: 'pointer' }}>
                                <Box borderBottom={'1px solid #00000005'} paddingBlock={'20px '} paddingLeft={'12px'} paddingRight={'20px'}
                                    bgcolor={ActiveElement === 2 ? '#FAFBFF' : '#fff'}>
                                    <Grid container alignItems={'center'} columnGap={'10px'}>
                                        <Grid item xs>
                                            <Typography fontSize={{ xs: '16px' }}>
                                                Wie reiche ich einen Anspruch ein?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Arrow />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box paddingBlock={'40px'} paddingLeft={{ xs: '20px', md: '80px' }} paddingRight={'20px'}
                            zIndex={-1} position={'relative'}
                            bgcolor={'#FAFBFF'} borderBottom={'1px solid #00000005'} marginLeft={{ xs: '0', md: '-40px' }}>
                            <Typography variant='h1' fontSize={'16px'} fontWeight={700} color={'#18191F'} marginBottom={'3rem'}>
                                {
                                    {
                                        0: 'Welche Leistungen sind in meinem Plan abgedeckt?',
                                        1: 'Kann ich meinen Versicherungsschutz anpassen?',
                                        2: 'Wie reiche ich einen Anspruch ein?'
                                    }[ActiveElement]
                                }
                            </Typography>
                            <div>
                                <span>
                                    {
                                        {
                                            0: ' Unsere Pläne bieten umfassenden Schutz für eine Vielzahl von Gesundheitsleistungen, darunter Vorsorge, Arztbesuche, Krankenhausaufenthalte und vieles mehr.',
                                            1: 'Ja, Prämienvergleich ermöglicht Ihnen flexible Anpassungen, um sicherzustellen, dass Ihre Versicherung Ihren aktuellen Bedürfnissen entspricht.',
                                            2: 'Unser Team unterstützt Sie bei der Einreichung und Abwicklung Ihrer Ansprüche, damit Sie schnell und unkompliziert die Leistungen erhalten, die Ihnen zustehen." '
                                        }[ActiveElement]
                                    }

                                </span>
                            </div>
                            {
                                ActiveElement === 0 && (
                                    <ul>
                                        <li> Vorsorge: Jährliche Untersuchungen, Screenings und Impfungen</li>
                                        <li> Arztbesuche: Hausärzte, Fachärzte und Telemedizin.</li>
                                        <li> Krankenhausaufenthalte: Stationäre und ambulante Leistungen, Operationen und Notfallversorgung.</li>
                                        <li> Verschreibungspflichtige Medikamente: Abdeckung für Generika und Markenmedikamente.</li>
                                        <li> Psychische Gesundheitsdienste: Beratung, Therapie und psychiatrische Betreuung</li>
                                    </ul>
                                )
                            }

                        </Box>
                    </Grid>
                </Grid>
                <SectionSpacing />

            </Container>
            <Box bgcolor={'#0D1C3D'} paddingBlock={'3rem'}>
                <Container>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>

                        <Typography color={'#fff'} fontSize={{ xs: '22px', sm: '25px', md: '35px', lg: '40px' }} fontWeight={500} textAlign={'center'} variant='h1' marginBottom={'1rem'}>
                            Jetzt vergleichen
                        </Typography>

                        <Typography color={'#fff'} fontSize={{ xs: '16px', sm: '18px' }} textAlign={'center'} marginBottom={'2rem'}>
                            Finden Sie Antworten auf die am häufigsten gestellten Fragen
                        </Typography>
                        <Link to={'/form'} style={{ textDecoration: 'none', color: '#0D1C3D', cursor: 'pointer' }}>
                            <Button>Jetzt vergleichen</Button>
                        </Link>
                    </Box>
                </Container>
            </Box>
        </>

    )
}

export default Faq