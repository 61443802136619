import React from 'react'
import Container from '../../components/Container'
import SectionSpacing from '../../components/SectionSpacing'

import HowDoesItWorkIllu from '../../assets/images/howItWorks.png'
import { Box, Grid, Typography } from '@mui/material'

const HowDoesItWork = () => {
    return (
        <Container>
            {/* <Grid container alignItems={'center'} rowGap={'30px'}>
                <Grid item xs>
                    <Box padding={'20px'} border={'1px solid #D9D9D9'} borderRadius={'4px'} bgcolor={'#F7F7F7'}>
                        <img src={HowDoesItWorkIllu} alt='How does it work' width={'70%'} style={{ visibility: 'hidden' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Box maxWidth={'410px'} marginLeft={{ xs: '0px', sm: '-150px' }}
                        boxShadow={'0px 3.86px 19.31px 0px #0000000A'} border={'1px solid #0000001A'}
                        borderRadius={'4px'}
                        bgcolor={'#fff'}
                        padding={{ xs: '25px', md: '30px' }}>
                        <Typography variant='h1' fontSize={{ xs: '18px', sm: '20px' }} marginBottom={'1rem'} color={'#003B71'}>Today's suggestions:</Typography>
                        <Box maxWidth={'310px'} width={'100%'}>
                            <Grid container spacing={'10px'}>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Shop plans
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Medicaid renewal
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Find a doctor
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Mental health services
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Pharmacy
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Box borderRadius={'50px'} border={'1px solid #0078B3'} bgcolor={'#F0F7FA'} padding={'10px 15px'} color={'#003B71'} fontSize={'14px'}>
                                        <span>
                                            Social health
                                        </span>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </Grid>
            </Grid> */}
            <Box>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12} md={"auto"}>
                        <Box width={{ md: '310px', xl: '410px' }}
                            boxShadow={'0px 3.86px 19.31px 0px #0000000A'} border={'1px solid #0000001A'}
                            borderRight={{ xs: '1px solid #0000001A', md: 'none' }}
                            borderRadius={{ xs: '4px', md: '4px 0px 0px 4px' }}
                            bgcolor={'#fff'}
                            padding={{ xs: '25px', md: '40px' }}>
                            <Typography variant='h1' fontSize={{ xs: '22px', md: '25px', xl: '32px' }} fontWeight={600} marginBottom={'1.5rem'}>
                                Wie funktioniert die Krankenversicherung?
                            </Typography>
                            <Typography fontSize={{ xs: '18px', md: '20px' }} fontWeight={400} marginBottom={'1.5rem'}>
                                Erfahren Sie, wie der richtige Plan Sie gesund halten kann, während er Ihnen Geld spart, und wie Sie das Beste aus Ihrem Kaiser Permanente-Plan herausholen können.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md>
                        <Box marginLeft={{ xs: '0px', md: '-20px' }}>

                            <img src={HowDoesItWorkIllu} alt='How does it work' width={'100%'} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SectionSpacing />
        </Container>
    )
}

export default HowDoesItWork