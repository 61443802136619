import React, { useEffect, useState } from 'react'
import Container from './Container'
import { Box, Grid } from '@mui/material'
import Button from './Button'

import { ReactComponent as Logo } from '../assets/svgs/logo.svg'


const Header = () => {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])
    return (
        <Box position={'fixed'} left={0} top={0} width={'100%'} bgcolor={'#0D1C3D'} zIndex={'9999'} paddingBlock={'1.25rem'}>

            <Container>
                <Grid container justifyContent={'space-between'} alignItems={"center"}>
                    <Grid item xs={'auto'} lineHeight={0}>
                       <a href='/'>
                         <Logo style={{ width: '200px', height: 'auto' }} />
                       </a>
                    </Grid>

                    {/* {(screenWidth < 1099.98) ? (
                        <Grid item xs={'auto'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20" fill="none">
                                <path fill="#fff" fill-rule="evenodd" d="M19 4a1 1 0 01-1 1H2a1 1 0 010-2h16a1 1 0 011 1zm0 6a1 1 0 01-1 1H2a1 1 0 110-2h16a1 1 0 011 1zm-1 7a1 1 0 100-2H2a1 1 0 100 2h16z" />
                            </svg>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={'auto'}>
                                <Grid container columnSpacing={'30px'}>
                                    <Grid item xs={'auto'}>
                                        <span className='openSansFont' style={{ color: '#fff' }}>Learn</span>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <span className='openSansFont' style={{ color: '#fff' }}>Shop Plans</span>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <span className='openSansFont' style={{ color: '#fff' }}>Doctors & Locations</span>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <span className='openSansFont' style={{ color: '#fff' }}>Health & Wellness</span>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <span className='openSansFont' style={{ color: '#fff' }}>Get Care</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Grid container columnSpacing={'15px'}>
                                    <Grid item xs={'auto'}>
                                        <Button secondary={true}>
                                            Register
                                        </Button>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Button >
                                            <Grid container columnSpacing={'5px'} alignItems={'center'}>
                                                <Grid item xs={'auto'} lineHeight={0}>

                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.97998 10C0.97998 4.49 5.46998 0 10.98 0C16.49 0 20.98 4.49 20.98 10C20.98 15.51 16.49 20 10.98 20C5.46998 20 0.97998 15.51 0.97998 10ZM13.73 12.02C15.45 12.2 16.83 13.38 16.96 15.3C18.21 13.89 18.98 12.03 18.98 10C18.98 5.59 15.39 2 10.98 2C6.56998 2 2.97998 5.59 2.97998 10C2.97998 12.03 3.74998 13.89 4.99998 15.3C5.12998 13.38 6.50998 12.2 8.22998 12.02C8.33998 12.01 8.45998 12 8.56998 12H13.39C13.5 12 13.62 12.01 13.73 12.02ZM13.98 8C13.98 9.6569 12.6369 11 10.98 11C9.32308 11 7.97998 9.6569 7.97998 8C7.97998 6.34315 9.32308 5 10.98 5C12.6369 5 13.98 6.34315 13.98 8Z" fill="#003B71" />
                                                    </svg>

                                                </Grid>
                                                <Grid item lineHeight={0}>

                                                    <span style={{ lineHeight: '0px' }}>Sign In</span>
                                                </Grid>
                                            </Grid>
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )} */}
                </Grid>

            </Container>
        </Box>
    )
}

export default Header