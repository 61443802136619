import React from 'react'
import HeroSection from './home/HeroSection'
import WhySection from './home/WhySection'
import HowDoesItWork from './home/HowDoesItWork'
import Testimonials from './home/Testimonials'
import Faq from './home/Faq'
import Footer from '../components/Footer'

const Home = () => {
    return (
        <>
            <HeroSection />
            <WhySection />
            <HowDoesItWork />
            <Testimonials />
            <Faq />
            <Footer />
        </>
    )
}

export default Home